import React from 'react'
import { navigate } from 'gatsby'

import Layout from '../components/Layouts/cakeDefault'

const Products = ({ path }) => {
  if (path === `/products` || path === `/products/`) {
    return navigate('/')
  }
  return <Layout>Products</Layout>
}
export default Products
